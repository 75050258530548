<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

/**
 * Starter page component
 */
export default {
  page() {
    return {
      title: this.$t("admin.group.text"),
      meta: [{ name: "description" }],
    };
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Starter page",
      items: [
        {
          text: "Utility",
          href: "/",
        },
        {
          text: "Starter page",
          active: true,
        },
      ],

      ismobile: false,
      activeNames: [],
      activeapp: [],
      loading: true,
      usergroup: [],
      addusertogroup: "",
      newgroupdata: {
        uid: "",
        user: [],
        name: "",
        type: "",
      },
      addusergrouptip: false,
      addnewgroup: false,
      userlist: [],
      addusergroupid: 0,
      saveloading: false,
    };
  },
  mounted() {
    this.title = this.$t("admin.group.text");

    this.items = [
      {
        text: this.$t("menuitems.user.text"),
        href: "/user/list",
      },
      {
        text: this.$t("admin.group.text"),
        active: true,
      },
    ];

    this.userinfo = JSON.parse(localStorage.getItem("user"));
    let flag = navigator.userAgent.match(
      /(phone|pod|iPhone|iPod|ios|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    );
    if (flag) {
      this.ismobile = true;
    }
    this.getusergroup();
  },
  methods: {
    getusergroup() {
      var that = this;
      that.$axios
        .post(
          that.apiuri,
          {
            action: "getusergroup",
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.loading = false;
          that.usergroup = response.data.data;
          that.getuserlist();
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    removeuser(uid, idx) {
      var that = this;
      that.$axios
        .post(
          that.apiuri,
          {
            action: "rmusergroup",
            uid: uid,
            group: idx,
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.getusergroup();
          if (response.data.status == 200) {
            that.$message({
              message: that.$t("global.success"),
              type: "success",
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getuserlist() {
      var that = this;
      that.$axios
        .post(
          that.apiuri,
          {
            action: "getwhitelist",
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.userlist = response.data.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    showaddgroupuserdelog(id) {
      var that = this;
      that.addusergroupid = id;
      that.addusergrouptip = true;
    },
    addgroupuser() {
      var that = this;
      that.saveloading = true;
      that.$axios
        .post(
          that.apiuri,
          {
            action: "saveusergroup",
            id: that.addusergroupid,
            user: that.addusertogroup,
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.saveloading = false;

          if (response.data.status == 200) {
            that.addusergrouptip = false;
            that.addusertogroup = [];
            that.getusergroup();
            that.$message({
              message: that.$t("global.success"),
              type: "success",
            });
          } else if (response.data.status == 407) {
            that.$message.error(
              that.$t("global.errordata.groupuserexit", {
                list: response.data.userlist,
              })
            );
          } else {
            that.$message.error(that.$t("global.delete.500"));
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    addnewgroupaction() {
      this.addnewgroup = true;
    },
    deletegroup(id) {
      var that = this;
      that.$axios
        .post(
          that.apiuri,
          {
            action: "rmgroup",
            group: id,
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          if (response.data.status == 200) {
            that.getusergroup();
            that.$message({
              message: that.$t("global.success"),
              type: "success",
            });
          } else {
            that.$message.error(that.$t("global.delete.500"));
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    addgroup() {
      var that = this;
      that.saveloading = true;
      that.$axios
        .post(
          that.apiuri,
          {
            action: "addnewusergroup",
            uid: that.newgroupdata.uid,
            user: that.newgroupdata.user,
            name: that.newgroupdata.name,
            type: that.newgroupdata.type,
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.saveloading = false;

          if (response.data.status == 200) {
            that.addnewgroup = false;
            that.getusergroup();
            that.newgroupdata.user = [];
            that.newgroupdata.uid = "";
            that.newgroupdata.name = "";
            that.newgroupdata.type = "";
            that.$message({
              message: that.$t("global.success"),
              type: "success",
            });
          } else if (response.data.status == 407) {
            that.$message.error(
              that.$t("global.errordata.groupuserexit", {
                list: response.data.userlist,
              })
            );
          } else {
            that.$message.error(that.$t("global.delete.500"));
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    totemperuser(hex) {
      this.$router.push({
        path: "/user/temporary",
        query: { hex: hex },
      });
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body" v-loading="loading">
            <p>
              <el-button type="primary" @click="addnewgroupaction" round>{{
                $t("user.group.add")
              }}</el-button>
            </p>
            <table class="table">
              <thead>
                <tr>
                  <th>{{ $t("user.group.id") }}</th>
                  <th>{{ $t("user.group.name") }}</th>
                  <th>{{ $t("user.group.member") }}</th>
                  <th>{{ $t("user.group.consumption") }}</th>
                  <th>{{ $t("user.group.totalvalue") }}</th>
                  <th>{{ $t("user.group.action") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(val, idx) in usergroup" :key="idx">
                  <td>{{ val.id }}</td>
                  <td>{{ val.name }}</td>
                  <td>
                    <el-tag
                      v-for="(tag, idx) in val.user"
                      :key="idx"
                      closable
                      size="mini"
                      @close="removeuser(tag.id, val.id)"
                      @click="totemperuser(tag.hex)"
                      v-show="tag.id != val.uid"
                    >
                      <span v-if="tag.remark">{{ tag.remark }}</span>
                      <span v-else>{{ tag.hex }}</span>
                    </el-tag>
                  </td>
                  <td>{{ val.total }}</td>
                  <td>{{ val.sum }}ML</td>
                  <td>
                    <el-tooltip
                      class="item"
                      effect="dark"
                      :content="$t('user.group.adduser')"
                      placement="top-start"
                    >
                      <el-button
                        type="success"
                        icon="el-icon-plus"
                        circle
                        size="mini"
                        @click="showaddgroupuserdelog(val.id)"
                      ></el-button>
                    </el-tooltip>
                    <el-tooltip
                      class="item"
                      effect="dark"
                      :content="$t('user.group.search')"
                      placement="top-start"
                    >
                      <router-link
                        :to="{
                          path: '/device/grouporder',
                          query: { id: val.id },
                        }"
                      >
                        <el-button
                          icon="el-icon-search"
                          circle
                          size="mini"
                        ></el-button>
                      </router-link>
                    </el-tooltip>
                    <el-tooltip
                      class="item"
                      effect="dark"
                      :content="$t('user.group.delete')"
                      placement="top-start"
                    >
                      <el-button
                        type="danger"
                        icon="el-icon-delete"
                        circle
                        size="mini"
                        @click="deletegroup(val.id)"
                      ></el-button>
                    </el-tooltip>
                  </td>
                </tr>
              </tbody>
            </table>
            <!-- 添加用户提示框 -->
            <el-dialog
              :title="$t('user.group.adduser')"
              :visible.sync="addusergrouptip"
            >
              <el-form label-width="80px">
                <el-form-item :label="$t('user.group.user')">
                  <el-select
                    v-model="addusertogroup"
                    multiple
                    filterable
                    :placeholder="$t('user.group.seletuser')"
                  >
                    <el-option
                      v-for="(val, idx) in userlist"
                      :key="idx"
                      :label="val.remark"
                      :value="val.id"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-form>
              <div slot="footer" class="dialog-footer">
                <el-button
                  @click="
                    addusergrouptip = false;
                    addusergroupid = 0;
                  "
                  >{{ $t("global.button.cancel") }}</el-button
                >
                <el-button
                  type="primary"
                  :loading="saveloading"
                  @click="addgroupuser"
                  >{{ $t("global.button.ok") }}</el-button
                >
              </div>
            </el-dialog>
            <!-- 添加新用户组 -->
            <el-dialog
              :title="$t('user.group.addnewgroup')"
              :visible.sync="addnewgroup"
            >
              <el-form label-width="80px">
                <el-form-item :label="$t('user.group.name')">
                  <el-input v-model="newgroupdata.name"></el-input>
                </el-form-item>
                <el-form-item :label="$t('user.group.checkoutperson')">
                  <el-select
                    v-model="newgroupdata.uid"
                    filterable
                    :placeholder="$t('user.group.seletuser')"
                  >
                    <el-option
                      v-for="(val, idx) in userlist"
                      :key="idx"
                      :label="val.remark"
                      :value="val.id"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item :label="$t('user.group.user')">
                  <el-select
                    v-model="newgroupdata.user"
                    multiple
                    filterable
                    :placeholder="$t('user.group.seletuser')"
                  >
                    <el-option
                      v-for="(val, idx) in userlist"
                      :key="idx"
                      :label="val.remark"
                      :value="val.id"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item :label="$t('user.group.checkouttype')">
                  <el-select
                    v-model="newgroupdata.type"
                    :placeholder="$t('user.group.checkouttype')"
                  >
                    <el-option
                      :label="
                        $t('user.group.checkoutperson') +
                        $t('user.group.checkout')
                      "
                      value="0"
                    ></el-option>
                    <el-option
                      :label="
                        $t('user.group.member') + $t('user.group.checkout')
                      "
                      value="2"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-form>
              <div slot="footer" class="dialog-footer">
                <el-button @click="addnewgroup = false">{{
                  $t("global.button.cancel")
                }}</el-button>
                <el-button
                  type="primary"
                  :loading="saveloading"
                  @click="addgroup"
                  >{{ $t("global.button.ok") }}</el-button
                >
              </div>
            </el-dialog>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>